<template>
  <div class="slim-page">
    <settings-page-header section="account" page="notifications" />
    <div class="column no-wrap q-py-md q-px-md">
      <div class="row no-wrap items-center text-subtitle1">
        <div class="col">{{ $t("settings.account.notifications.event") }}</div>
        <div class="checkbox-col">
          {{ $t("settings.account.notifications.inApp") }}
        </div>
        <div class="checkbox-col">
          {{ $t("settings.account.notifications.email") }}
        </div>
      </div>
      <q-separator class="q-my-md" />
      <div
        v-for="setting in Object.keys(notificationSettingsPerType)"
        :key="setting"
        class="row no-wrap items-center q-py-sm"
      >
        <div class="col">
          {{ $t(`settings.account.notifications.${setting}`) }}
        </div>
        <div class="checkbox-col">
          <q-checkbox
            dense
            v-if="user"
            v-model="
              localNotificationSettings[
                notificationSettingsPerType[setting].inApp
              ]
            "
            :disable="isUpdating || !user"
          />
        </div>
        <div class="checkbox-col">
          <q-checkbox
            dense
            v-model="
              localNotificationSettings[
                notificationSettingsPerType[setting].email
              ]
            "
            :disable="isUpdating || !user"
          />
        </div>
      </div>
      <q-separator class="q-my-md" />
    </div>
    <div class="row no-wrap justify-end q-mt-lg">
      <q-btn
        dense
        :disable="!user"
        :loading="isUpdating"
        :label="$t('settings.account.notifications.save')"
        color="primary"
        @click="update"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import SettingsPageHeader from "@/components/Settings/SettingsPageHeader.vue";
import { useCurrentUserStore } from "@/stores/currentUser";
import type { User } from "@/types/user";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const userStore = useCurrentUserStore();
const { user } = storeToRefs(userStore);
const isUpdating = ref(false);

if (!user.value) {
  userStore.loadUser();
}

const notificationSettings: (keyof User)[] = [
  "emailNotificationsOnMention",
  "inAppNotificationsOnMention",
  "emailNotificationsOnSelfAssigned",
  "inAppNotificationsOnSelfAssigned",
  "emailNotificationsOnInquiryStatusChanged",
  "inAppNotificationsOnInquiryStatusChanged",
  "emailNotificationsOnInquiryExported",
  "inAppNotificationsOnInquiryExported",
  "emailNotificationsOnInquiryFinalized",
  "inAppNotificationsOnInquiryFinalized",
  "emailNotificationsOnInquiryMovedToAnotherInbox",
  "inAppNotificationsOnInquiryMovedToAnotherInbox",
  "emailNotificationsOnInquiryAssignedUser",
  "inAppNotificationsOnInquiryAssignedUser",
  "emailNotificationsOnInquiryComment",
  "inAppNotificationsOnInquiryComment",
  "emailNotificationsOnInquiryCopied",
  "inAppNotificationsOnInquiryCopied",
];

const notificationSettingsPerType: {
  [key: string]: { email: keyof User; inApp: keyof User };
} = {
  notificationsOnMention: {
    email: "emailNotificationsOnMention",
    inApp: "inAppNotificationsOnMention",
  },
  notificationsOnSelfAssigned: {
    email: "emailNotificationsOnSelfAssigned",
    inApp: "inAppNotificationsOnSelfAssigned",
  },
  notificationsOnInquiryStatusChanged: {
    email: "emailNotificationsOnInquiryStatusChanged",
    inApp: "inAppNotificationsOnInquiryStatusChanged",
  },
  notificationsOnInquiryExported: {
    email: "emailNotificationsOnInquiryExported",
    inApp: "inAppNotificationsOnInquiryExported",
  },
  notificationsOnInquiryFinalized: {
    email: "emailNotificationsOnInquiryFinalized",
    inApp: "inAppNotificationsOnInquiryFinalized",
  },
  notificationsOnInquiryMovedToAnotherInbox: {
    email: "emailNotificationsOnInquiryMovedToAnotherInbox",
    inApp: "inAppNotificationsOnInquiryMovedToAnotherInbox",
  },
  notificationsOnInquiryAssignedUser: {
    email: "emailNotificationsOnInquiryAssignedUser",
    inApp: "inAppNotificationsOnInquiryAssignedUser",
  },
  notificationsOnInquiryComment: {
    email: "emailNotificationsOnInquiryComment",
    inApp: "inAppNotificationsOnInquiryComment",
  },
  notificationsOnInquiryCopied: {
    email: "emailNotificationsOnInquiryCopied",
    inApp: "inAppNotificationsOnInquiryCopied",
  },
};

const localNotificationSettings = ref(
  Object.fromEntries(
    notificationSettings.map((setting) => [setting, user.value?.[setting]])
  )
);
watch(user, () => {
  localNotificationSettings.value = Object.fromEntries(
    notificationSettings.map((setting) => [setting, user.value?.[setting]])
  );
});

const q = useQuasar();
const { t } = useI18n();

async function update() {
  if (isUpdating.value || !user.value) {
    return;
  }

  isUpdating.value = true;
  try {
    await userStore.updateUser(localNotificationSettings.value);
    q.notify({
      type: "positive",
      message: t("settings.account.notifications.notificationsUpdated"),
    });
  } finally {
    isUpdating.value = false;
  }
}
</script>

<style scoped lang="scss">
.checkbox-col {
  width: 100px;
  flex-shrink: 0;
  text-align: center;
}
</style>
